import React from 'react';
import {Link} from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'

import blog1 from '../../images/blog/img-10.jpg'
import blog2 from '../../images/blog/img-8.jpg'
import blog3 from '../../images/blog/img-9.jpg'
import blog4 from '../../images/blog/admin.jpg'


const BlogFullwidth = () => {

    return(
        <section className="tp-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-12">
                    <div className="tp-tp-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog1} alt=""/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={blog4} alt=""/> بواسطة Admin</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-calendar"></i> 25 سبتمبر 2021</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link to="/blog-details">مساعدة من هم في حاجة لك.</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة.</p>
                                <Link to="/blog-details" className="read-more">اقرأ أكثر...</Link>
                            </div>
                            <div className="post format-video">
                                <div className="entry-media video-holder">
                                    <img src={blog2} alt=""/>
                                    <div className="video-btn2">
                                        <VideoModal/>
                                    </div>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={blog4} alt=""/> بواسطة Admin</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-calendar"></i> 25 سبتمبر 2021</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link to="/blog-details">إنهم ينتظرون مساعدتك.</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة.</p>
                                <Link to="/blog-details" className="read-more">اقرأ أكثر...</Link>
                            </div>
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog3} alt=""/>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={blog4} alt=""/> بواسطة Admin</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-calendar"></i> 25 سبتمبر 2021</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link to="/blog-details">مساعدة من هم في حاجة لك.</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. </p>
                                <Link to="/blog-details" className="read-more">اقرأ أكثر...</Link>
                            </div>

                            <div className="post format-quote">
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={blog4} alt=""/> بواسطة Admin</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-calendar"></i> 25 سبتمبر 2021</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                                </ul>
                                <h3><Link to="/blog-details">إنهم ينتظرون مساعدتك.</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. </p>
                            </div>
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog-details" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog-details">1</Link></li>
                                    <li><Link to="/blog-details">2</Link></li>
                                    <li><Link to="/blog-details">3</Link></li>
                                    <li><Link to="/blog-details">4</Link></li>
                                    <li>
                                        <Link to="/blog-details" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     )
        
}

export default BlogFullwidth;
