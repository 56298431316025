import React from 'react'
import {Link} from 'react-router-dom'
import erimg from '../../images/error-404.png'
import './style.css'

const Error = (props) => {
    return(
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error">
                                <img src={erimg} alt=""/>
                            </div>
                            <div className="error-message">
                                <h3>وجه الفتاة! الصفحة غير موجودة!</h3>
                                <p>نأسف ولكن يبدو أننا لا نعثر على الصفحة التي طلبتها. قد يكون هذا بسبب كتابة عنوان الويب بشكل غير صحيح.</p>
                                <Link to="/home" className="theme-btn"><i className="fa fa-angle-double-left"></i> العودة إلى المنزل</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;