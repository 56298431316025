import React from 'react';
import { Link } from 'react-router-dom'
import blog1 from '../../images/blog/img-10.jpg'
import blog2 from '../../images/blog-details/author.jpg'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import blog6 from '../../images/blog/admin.jpg'


const BlogDetailsFullwidth = () => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }
    return (
        <section className="tp-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="tp-tp-blog-content clearfix">
                            <div className="post">
                                <div className="entry-media">
                                    <img src={blog1} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={blog6} alt="" />  بواسطة Admin</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-calendar"></i> 25 سبتمبر 2021</Link></li>
                                    <li><Link to="/blog-details"><i className="ti-heart"></i> 35</Link></li>
                                </ul>
                                <h2>مساعدة من هم في حاجة لك.</h2>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. العديد من حزم النشر المكتبي ومحرري صفحات الويب الآن.</p>
                                <p>الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. العديد من حزم النشر المكتبي ومحرري صفحات الويب الآن.</p>
                                <blockquote>Lorem ipsum dolor sit amet، consectetur adipiscing elit، sed do eiusmod tempor incidunt ut labore et dolore magna aliqua. Quis ipsum Suspendisse ultrices gravida. </blockquote>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. العديد من حزم النشر المكتبي ومحرري صفحات الويب الآن.</p>
                                <p>الهدف من استخدام لوريم إيبسوم هو أنه يحتوي على توزيع طبيعي -إلى حد ما- للأحرف ، بدلاً من استخدام "هنا يوجد محتوى نصي ، هنا يوجد محتوى نصي" ، مما يجعلها تبدو وكأنها إنجليزية قابلة للقراءة. العديد من حزم النشر المكتبي ومحرري صفحات الويب الآن.</p>
                            </div>
                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <ul>
                                        <li><Link to="/blog-details">اعمال</Link></li>
                                        <li><Link to="/blog-details">تسويق</Link></li>
                                        <li><Link to="/blog-details">SEO</Link></li>
                                    </ul>
                                </div>
                                <div className="share">
                                    <ul>
                                        <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="author-box">
                                <div className="author-avatar">
                                    <Link to="/blog-details" target="_blank"><img src={blog2} alt="" />  </Link>
                                </div>
                                <div className="author-content">
                                    <Link to="/blog-details" className="author-name">Henry Joyes</Link>
                                    <p>يتوفر لوريم إيبسوم ، لكن الغالبية عانت من تغيير بشكل ما ، عن طريق الفكاهة المحقونة ، أو العشوائية</p>
                                    <div className="author-btn">
                                        <Link to="/blog-details">كل المنشورات من المؤلف</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="more-posts clearfix">
                                <div className="previous-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">سابق</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">التالي بريد</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="tp-blog-single-section wrap-even">
                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">تعليقات</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"> <img src={blog3} alt=""/> </div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>جون ابراهام <span className="comments-date"> أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-details"><i className="fa fa-reply" aria-hidden="true"></i><span>رد</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"> <img src={blog4} alt=""/></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>ليلي واتسون <span className="comments-date">أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، </p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> رد</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt=""/> </div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>جون ابراهام <span className="comments-date">أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> رد</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </div>
                            </div> 
                            <div className="comment-respond">
                                <h3 className="comment-reply-title">اترك تعليقا</h3>
                                <form method="post" id="commentform" className="comment-form" onSubmit={SubmitHandler}>
                                    <div className="form-inputs">
                                        <input placeholder="Name" type="text"/>
                                        <input placeholder="Email" type="email"/>
                                        <input placeholder="Website" type="url"/>
                                    </div>
                                    <div className="form-textarea">
                                        <textarea id="comment" placeholder="اكتب تعليقاتك ..."></textarea>
                                    </div>
                                    <div className="form-submit">
                                        <input id="submit" value="رد" type="submit"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default BlogDetailsFullwidth;


