import React from 'react'
import event1 from '../../images/event/1.jpg'
import event2 from '../../images/event/2.jpg'
import event3 from '../../images/event/3.jpg'
import event4 from '../../images/event/1.png'
import event5 from '../../images/event/2.png'
import {Link} from 'react-router-dom'

import './style.css'

const EventSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className="event-area section-padding">
            <div className="container">
                <div className="row  justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title section-title2 text-center">
                            <div className="thumb-text">
                                <span>الأحداث</span>
                            </div>
                            <h2>أحداثنا القادمة</h2>
                            <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء خارج الصفحة يلهي القارئ عن طريق النظر إلى نقطة التخطيط.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="event-item">
                            <div className="event-img">
                                <img src={event1} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-left">
                                    <div className="event-l-text">
                                        <span>مارس</span>
                                        <h4>28</h4>
                                    </div>
                                </div>
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-location-pin"></i> 9:00 ص - 10:00 ص</li>
                                        <li><i className="ti-location-pin"></i> 968 ، مدكرم ، باكستان.</li>
                                    </ul>
                                    <h2><Link onClick={ClickHandler} to="/event">حدث تمويل يمكن أن يغير بعض الأطفال الفقراء.</Link></h2>
                                    <p>من الحقائق المؤكدة منذ زمن طويل أن القارئ سوف يشتت انتباهه بسبب وجود سلعة نتيجة لذلك.</p>
                                </div>
                            </div>
                        </div>
                        <div className="event-item">
                            <div className="event-img">
                                <img src={event2} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-left">
                                    <div className="event-l-text">
                                        <span>مارس</span>
                                        <h4>28</h4>
                                    </div>
                                </div>
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-location-pin"></i> 9:00 ص - 10:00 ص</li>
                                        <li><i className="ti-location-pin"></i> 968 ، مدكرم ، باكستان.</li>
                                    </ul>
                                    <h2><Link onClick={ClickHandler} to="/event">يعاني الكثير من الأطفال كثيرًا من أجل الطعام.</Link></h2>
                                    <p>من الحقائق المؤكدة منذ زمن طويل أن القارئ سوف يشتت انتباهه بسبب وجود سلعة نتيجة لذلك.</p>
                                </div>
                            </div>
                        </div>
                        <div className="event-item">
                            <div className="event-img">
                                <img src={event3} alt="" />
                            </div>
                            <div className="event-text">
                                <div className="event-left">
                                    <div className="event-l-text">
                                        <span>مارس</span>
                                        <h4>28</h4>
                                    </div>
                                </div>
                                <div className="event-right">
                                    <ul>
                                        <li><i className="ti-location-pin"></i> 9:00 ص - 10:00 ص</li>
                                        <li><i className="ti-location-pin"></i> 968 ، مدكرم ، باكستان.</li>
                                    </ul>
                                    <h2><Link onClick={ClickHandler} to="/event">كن لطيفًا مع الفقراء والأطفال.</Link></h2>
                                    <p>من الحقائق المؤكدة منذ فترة طويلة أن القارئ سوف يشتت انتباهه..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape1"><img src={event4} alt="" /></div>
            <div className="shape2"><img src={event5} alt="" /></div>
        </div>
    )
}

export default EventSection;