import React from 'react';

import {Link} from 'react-router-dom'
import SidebarWrap from '../SidebarWrap'
import simg from '../../images/event-details.jpg'

import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'

import './style.css'

const CaseSingle = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

        return (
            <div className="tp-case-details-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8">
                        <div class="tp-case-details-wrap">
                            <div class="tp-case-details-text">
                                <div id="Description">
                                    <div class="tp-case-details-img">
                                        <img src={simg} alt="" />
                                    </div>
                                    <div class="tp-case-content">
                                        <div class="tp-case-text-top">
                                            <h2>ضمان التعليم لكل طفل فقير</h2>
                                            <div class="progress-section">
                                                <div class="process">
                                                    <div class="progress">
                                                        <div class="progress-bar">
                                                            <div class="progress-value"><span>65.5</span>%</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul>
                                                <li><span>رفع:</span> $7,000.00</li>
                                                <li><span>هدف:</span> $8,000.00</li>
                                                <li><span>دونار:</span> 380</li>
                                            </ul>
                                            <div class="case-b-text">
                                                <p>من ناحية أخرى ، نشجب بسخط صالح ونكره الرجال الذين خدعهم سحر متعة اللحظة ، وأعمتهم الرغبة ، لدرجة أنهم لا يستطيعون التنبؤ بالألم والمتاعب التي لا بد أن تترتب على ذلك ، وينتمي اللوم المتساوي. إلى من يفشل في أداء واجبه بسبب ضعف الإرادة ، وهو نفس القول بالانكماش من الكد والألم.</p>
                                                <p>هذه الحالات بسيطة للغاية وسهلة التمييز. في ساعة مجانية ، عندما تكون قدرتنا على الاختيار غير مقيدة وعندما لا شيء يمنعنا من القيام بما نفضله ، يجب الترحيب بكل متعة وتجنب كل ألم.</p>
                                                <p> ولكن في ظروف معينة وبسبب ادعاءات الواجب أو التزامات العمل ، سيحدث في كثير من الأحيان أن الملذات يجب نبذها وقبول الإزعاج. لذلك يتمسك الحكيم دائمًا في هذه الأمور بمبدأ الاختيار هذا: فهو يرفض الملذات.</p>
                                            </div>
                                            <div class="case-bb-text">
                                                <h3>نريد أن نضمن التعليم للأطفال.</h3>
                                                <p>هذه الحالات بسيطة للغاية وسهلة التمييز. في ساعة حرة ، عندما تكون قوتنا في الاختيار غير مقيدة وعندما لا شيء يمنعنا من أن نكون قادرين على فعل أفضل ما نحب ، كل متعة.</p>
                                                <ul>
                                                    <li>لذلك فإن الرجل الحكيم دائمًا ما يتمسك بهذه الأمور.</li>
                                                    <li>في ساعة حرة ، عندما تكون لدينا القوة في الاختيار وعندما لا شيء.</li>
                                                    <li>كما أنه يتحمل الآلام لتجنب الآلام السيئة.</li>
                                                    <li>نستنكر بسخط الصالحين ونكره الرجال. </li>
                                                    <li>وهو نفس القول من خلال.</li>
                                                </ul>
                                            </div>
                                            <div class="submit-area sub-btn">
                                                <Link to="/donate" class="theme-btn submit-btn">تبرع الآن</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tp-blog-single-section wrap-even">
                                    <div className="comments-area">
                                        <div className="comments-section">
                                            <h3 className="comments-title">تعليقات</h3>
                                            <ol className="comments">
                                                <li className="comment even thread-even depth-1" id="comment-1">
                                                    <div id="div-comment-1">
                                                        <div className="comment-theme">
                                                            <div className="comment-image"> <img src={blog3} alt="" /> </div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>جون ابراهام <span className="comments-date">أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ،</p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/case-single"><i className="fa fa-reply" aria-hidden="true"></i><span>رد</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="children">
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"> <img src={blog4} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>ليلي واتسون <span className="comments-date">أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/case-single"><span><i className="fa fa-reply" aria-hidden="true"></i> رد</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul>
                                                                <li className="comment">
                                                                    <div>
                                                                        <div className="comment-theme">
                                                                            <div className="comment-image"><img src={blog5} alt="" /> </div>
                                                                        </div>
                                                                        <div className="comment-main-area">
                                                                            <div className="comment-wrapper">
                                                                                <div className="comments-meta">
                                                                                    <h4>جون ابراهام <span className="comments-date">أكتوبر 28،2018 الساعة 9:00 صباحًا</span></h4>
                                                                                </div>
                                                                                <div className="comment-area">
                                                                                    <p>سأقدم لك وصفًا كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، </p>
                                                                                    <div className="comments-reply">
                                                                                        <Link className="comment-reply-link" to="/case-single"><span><i className="fa fa-reply" aria-hidden="true"></i> رد</span></Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="comment-respond">
                                        <h3 className="comment-reply-title">اترك تعليقا</h3>
                                        <form onSubmit={SubmitHandler} className="comment-form">
                                            <div className="form-inputs">
                                                <input placeholder="اسم" type="text" />
                                                <input placeholder="بريد إلكتروني" type="email" />
                                                <input placeholder="موقع إلكتروني" type="url" />
                                            </div>
                                            <div className="form-textarea">
                                                <textarea id="comment" placeholder="اكتب تعليقاتك ..."></textarea>
                                            </div>
                                            <div className="form-submit">
                                                <input id="submit" value="رد" type="submit" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SidebarWrap/>
                </div>
            </div>
        </div>
            );
    }
    
    export default CaseSingle;
          
          
          
          
