import React from 'react';
import SidebarWrap from '../SidebarWrap'
import simg from '../../images/event-details2.jpg'




import './style.css'

const EventSingle = (props) => {

        return (
            <div className="wpo-event-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8">
                        <div className="tp-case-details-wrap">
                            <div className="tp-case-details-text">
                                <div id="Description">
                                    <div className="tp-case-details-img">
                                       <img src={simg} alt="" />
                                    </div>
                                    <div className="tp-case-content">
                                        <div className="tp-case-text-top">
                                            <h2>يعاني الكثير من الأطفال كثيرًا من أجل الطعام.</h2>
                                            <div className="case-b-text">
                                                <p>من ناحية أخرى ، نشجب بسخط صالح ونكره الرجال الذين خدعهم سحر متعة اللحظة ، وأعمتهم الرغبة ، لدرجة أنهم لا يستطيعون التنبؤ بالألم والمتاعب التي لا بد أن تترتب على ذلك ، وينتمي اللوم المتساوي. إلى من يفشل في أداء واجبه بسبب ضعف الإرادة ، وهو نفس القول بالانكماش من الكد والألم.</p>
                                                <p>هذه الحالات بسيطة للغاية وسهلة التمييز. في ساعة مجانية ، عندما تكون قدرتنا على الاختيار غير مقيدة وعندما لا شيء يمنعنا من القيام بما نفضله ، يجب الترحيب بكل متعة وتجنب كل ألم. </ p>
                                                <p>ولكن في ظروف معينة وبسبب ادعاءات الواجب أو التزامات العمل ، سيحدث في كثير من الأحيان أنه يجب نبذ الملذات وقبول الإزعاج. لذلك يتمسك الحكيم دائمًا في هذه الأمور بمبدأ الاختيار هذا: فهو يرفض الملذات.</p>
                                            </div>
                                            <div className="case-bb-text">
                                                <h3> مهمة الحدث</h3>
                                                <p>هذه الحالات بسيطة للغاية وسهلة التمييز. في ساعة حرة ، عندما تكون قوتنا في الاختيار غير مقيدة وعندما لا شيء يمنعنا من أن نكون قادرين على فعل أفضل ما نحب ، كل متعة.</p>
                                                <ul>
                                                    <li>تم الترحيب بدور إنقاذ دور الأطفال في مكافحة سوء التغذية</li>
                                                    <li>Charity Can Help Make Smile Of Poor People</li>
                                                    <li>كما أنه يتحمل الآلام لتجنب الآلام السيئة.</li>
                                                    <li>نستنكر بسخط الصالحين ونكره الرجال. </li>
                                                    <li>المساعدة المالية للعائلات الفقيرة</li>
                                                </ul>
                                            </div>
                                            <div className="case-bb-text">
                                                <h3>حدث Loacation</h3>
                                                <div id="Map" className="tab-pane">
                                                    <div className="contact-map">
                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-area sub-btn">
                                                <a href="/donate" className="theme-btn submit-btn">تبرع الآن</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <SidebarWrap/>

                    </div>
                </div>
            </div>

            );
    }
    
    export default EventSingle;
          
          
          
          
