import React from 'react';
import {Link} from 'react-router-dom'
import ins1 from '../../images/recent-posts/img-1.jpg'
import ins2 from '../../images/recent-posts/img-2.jpg'
import ins3 from '../../images/recent-posts/img-3.jpg'


const SidebarWrap = () => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }

    return(
        <div className="col col-lg-4 col-12">
            <div className="tp-blog-sidebar">
                <div className="widget search-widget">
                    <h3>ابحث هنا</h3>
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="بحث في المشاركة .."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget recent-post-widget">
                    <h3>المشاركات الاخيرة</h3>
                    <div className="posts">
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins1} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">يعاني الكثير من الأطفال كثيرًا من أجل الطعام.</Link></h4>
                                <span className="date">22 سبتمبر 2021</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins2} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">كن لطيفًا مع الفقراء والأطفال.</Link></h4>
                                <span className="date">22 سبتمبر 2021</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins3} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/blog-details">كن لطيفًا ولطيفًا مع الفقراء.</Link></h4>
                                <span className="date">22 سبتمبر 2021</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link to="/blog-details">التبرعات</Link></li>
                        <li><Link to="/blog-details">صدقة</Link></li>
                        <li><Link to="/blog-details">مساعدة</Link></li>
                        <li><Link to="/blog-details">غير ربحية</Link></li>
                        <li><Link to="/blog-details">اناس فقراء</Link></li>
                        <li><Link to="/blog-details">يد العون</Link></li>
                        <li><Link to="/blog-details">فيديو</Link></li>
                    </ul>
                </div>
            </div>
        </div>
     )
        
}

export default SidebarWrap;
