import React from 'react'
import {Link} from 'react-router-dom'
import causesimg from '../../images/cause/img-1.png'
import causesimg2 from '../../images/cause/img-2.jpg'
import causesimg3 from '../../images/cause/img-3.jpg'
import causesimg4 from '../../images/cause/img-4.jpg'
import causesimg5 from '../../images/cause/img-5.jpg'
import causesimg6 from '../../images/cause/img-6.jpg'
import './style.css'

const Causes = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="case-area section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section-title section-title2 text-center">
                            <div className="thumb-text">
                                <span>الأسباب</span>
                            </div>
                            <h2>أحدث ما تسبب في خيرة.</h2>
                            <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء خارج الصفحة يلهي القارئ عن طريق النظر إلى نقطة التخطيط.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">المساعدة المالية للعائلات الفقيرة</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg2} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">تعليم الأطفال الفقراء</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg3} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">أرسل الطفل إلى المدرسة لمدة عام</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg4} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">طعام ومنزل للأطفال</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg5} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">مياه نقية للعالم</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="cause-item">
                            <div className="cause-top">
                                <div className="cause-img">
                                    <img src={causesimg6} alt="" />
                                    <div className="case-btn">
                                        <Link onClick={ClickHandler} to="/donate" className="theme-btn">تبرع الآن<i className="fa fa-angle-double-right" aria-hidden="true"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="cause-text">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">الهدف: 9860 دولار</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">ارتفع: 768 دولارًا</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/case-single">إعادة التدوير للأعمال الخيرية</Link></h3>
                                <p>هناك حقيقة مثبتة منذ زمن طويل وهي أن القارئ سوف يشتت انتباهه.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Causes;